// https://launchcart.jp/blog/%E6%84%8F%E5%A4%96%E3%81%A8%E7%B0%A1%E5%8D%98%EF%BC%81jquery%E3%82%92%E4%BD%BF%E3%82%8F%E3%81%9A%E3%81%ABjavascript%E3%81%A0%E3%81%91%E3%81%A7%E3%82%B9%E3%83%A0%E3%83%BC%E3%82%B9%E3%82%B9%E3%82%AF/

export class Smooth {
  constructor() {
    const Ease = {
      easeInOut: (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
    }
    var duration = 500

    document.addEventListener('DOMContentLoaded', () => {
      var smoothScrollTriggers = document.querySelectorAll('a[href^="#"]')
      var node = Array.prototype.slice.call(smoothScrollTriggers, 0)
      node.forEach(function (smoothScrollTrigger) {
        smoothScrollTrigger.addEventListener('click', function (e) {
          var href = smoothScrollTrigger.getAttribute('href')
          var currentPostion = document.documentElement.scrollTop || document.body.scrollTop
          var targetElement = document.getElementById(href.replace('#', ''))

          if (targetElement) {
            e.preventDefault()
            e.stopPropagation()

            var targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - 115 // headerと余白の分だけずらす
            var startTime = performance.now()

            var loop = function (nowTime) {
              var time = nowTime - startTime
              var normalizedTime = time / duration

              if (normalizedTime < 1) {
                window.scrollTo(0, currentPostion + (targetPosition - currentPostion) * Ease.easeInOut(normalizedTime))
                requestAnimationFrame(loop)
              } else {
                window.scrollTo(0, targetPosition)
              }
            }
            requestAnimationFrame(loop)
          }
        })
      })
    })
  }
}
