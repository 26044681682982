import Swiper from 'swiper'
import 'swiper/css'

export class AboutGallery {
  constructor() {
    if (document.getElementById('js-about_slider') === null) return
    const aboutgallery = new Swiper('#js-about_slider', {
      slidesPerView: 1.6,
      breakpoints: {
        767: {
          slidesPerView: 2.2,
        },
      },
    })
  }
}
