import Swiper, { Navigation, EffectFade, Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'

export class KVSlider {
  constructor() {
    if (document.getElementById('js-kv_slider') === null) return
    const kv = new Swiper('#js-kv_slider', {
      modules: [Navigation, EffectFade, Autoplay, Pagination],
      slidesPerView: 1,
      effect: 'fade',
      speed: 2000,
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.js-kv_arrow_next',
        prevEl: '.js-kv_arrow_prev',
      },
      pagination: {
        el: '#js-kv_pager',
        clickable: true,
        renderBullet: function (index, className) {
          let num = index + 1
          return '<li class="p-index_kv__pager__item ' + className + '">' + ('00' + num).slice(-2) + '</li>'
        },
      },
    })
  }
}
