export class TextSplit {
  constructor() {
    const targetList = document.querySelectorAll('.js-sprit')
    const node = Array.prototype.slice.call(targetList, 0)
    node.forEach(function (target) {
      const text = target.textContent
      target.innerHTML = ''
      text.split('').forEach(function (c) {
        target.innerHTML += '<span>' + c + '</span>'
      })
    })
  }
}
