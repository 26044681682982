import Swiper, { Navigation, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'

export class EnjoySlider {
  constructor() {
    const sliderArr = Array.prototype.slice.call(document.querySelectorAll('.js-enjoy_block'), 0)
    sliderArr.forEach(function (elm) {
      let waySwiper = new Swiper(elm.querySelector('.js-enjoy_slider'), {
        modules: [Navigation, EffectFade],
        effect: 'fade',
        loop: false,
        slidesPerView: 1,
        navigation: {
          nextEl: elm.querySelector('.js-enjoy_arrow_next'),
          prevEl: elm.querySelector('.js-enjoy_arrow_prev'),
        },
      })
    })
  }
}
