export class Ui {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementById('js-ui') == null) return
      const html = document.querySelector('html')
      const body = document.querySelector('body')

      const init = () => {
        console.log('init')
        let currentBg = localStorage.getItem('fenice-sacay.jp-ui-bg') ? localStorage.getItem('fenice-sacay.jp-ui-bg') : 0
        let currentFont = localStorage.getItem('fenice-sacay.jp-ui-font') ? localStorage.getItem('fenice-sacay.jp-ui-font') : 0
        const ui = document.getElementById('js-ui')
        const bgButton = Array.prototype.slice.call(ui.querySelectorAll('.js-bg_button button'), 0)
        const fontParent = document.getElementById('js-font_button')
        const fontButton = Array.prototype.slice.call(ui.querySelectorAll('.js-font_button button'), 0)
        ChangeBg(currentBg)
        ChangeFont(currentFont)
        console.log(currentBg)
        console.log(currentFont)

        //=======================================
        // クリック時の動作
        //=======================================
        bgButton.forEach(function (elm, i) {
          if (Number(currentBg) == i) {
            elm.classList.add('is-act')
          } else {
            elm.classList.remove('is-act')
          }
          elm.addEventListener('click', (e) => {
            let $this = e.currentTarget
            let result = $this.getAttribute('data-id')
            bgButton.forEach(function (target) {
              target.classList.remove('is-act')
            })
            $this.classList.add('is-act')
            ChangeBg(result)
          })
        })

        fontButton.forEach(function (elm, i) {
          if (Number(currentFont) == i) {
            elm.classList.add('is-act')
          } else {
            elm.classList.remove('is-act')
          }
          elm.addEventListener('click', (e) => {
            let $this = e.currentTarget
            let result = $this.getAttribute('data-id')
            fontButton.forEach(function (target) {
              target.classList.remove('is-act')
            })
            $this.classList.add('is-act')
            ChangeFont(result)
          })
        })
      }

      const ChangeBg = (value) => {
        if (value == 0) {
          console.log('背景色：白')
          body.classList.remove('-bg-black')
          body.classList.remove('-bg-blue')
        }
        if (value == 1) {
          console.log('背景色：黒')
          body.classList.add('-bg-black')
          body.classList.remove('-bg-blue')
        }
        if (value == 2) {
          console.log('背景色：青')
          body.classList.add('-bg-blue')
          body.classList.remove('-bg-black')
        }
        localStorage.setItem('fenice-sacay.jp-ui-bg', value)
      }

      const ChangeFont = (value) => {
        if (value == 0) {
          console.log('文字サイズ：標準')
          html.style.fontSize = '62.5%'
        }
        if (value == 1) {
          console.log('文字サイズ：拡大')
          html.style.fontSize = '78.125%'
        }
        localStorage.setItem('fenice-sacay.jp-ui-font', value)
      }

      init()
    })
  }
}
