import { gsap } from 'gsap'
export class ShowPagetop {
  constructor() {
    if (document.getElementById('pagetop') == null) return
    const topBtn = document.getElementById('pagetop')
    window.addEventListener('scroll', (e) => {
      var y = window.pageYOffset
      if (y > 100) {
        gsap.to(topBtn, 0.3, { autoAlpha: 1, display: 'block' })
      } else {
        gsap.to(topBtn, 0.3, { autoAlpha: 0, display: 'none' })
      }
    })
  }
}
