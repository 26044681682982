export class Anchor {
  constructor() {
    const anchorTrgs = document.querySelectorAll('.js-anchor_trg')
    const anchorTrgArr = Array.prototype.slice.call(anchorTrgs, 0)
    const acoTrgs = document.querySelectorAll('.js-aco_trg')
    const acoTrgArr = Array.prototype.slice.call(acoTrgs, 0)
    anchorTrgArr.forEach(function (target) {
      //クリックした場合
      target.addEventListener('click', (event) => {
        let $this = event.currentTarget
        targetOpen($this)
      })
      //キーボード操作
      target.addEventListener('keypress', (event) => {
        if (event.keyCode === 13) {
          //他のものが開いてたら閉じる
          if (target.classList.contains('is-act')) {
            let closeContent = target.nextElementSibling
            closeContent.style.height = null
            closeContent.classList.remove('is-show')
          }
          let $this = event.currentTarget
          targetOpen($this)
        }
      })
    })

    let targetOpen = (elm) => {
      acoTrgArr.forEach(function (trg) {
        if (trg.classList.contains('is-act')) {
          let closeContent = trg.nextElementSibling
          closeContent.style.height = null
          closeContent.classList.remove('is-show')
          trg.classList.remove('is-act')
        }
      })

      let id = elm.getAttribute('href')
      let target = document.querySelector(id).querySelector('.js-aco_trg')
      let content = target.nextElementSibling

      let contentHeight = content.scrollHeight
      target.classList.toggle('is-act')
      content.classList.add('is-show')
      content.style.height = contentHeight + 'px'
      setTimeout(() => {
        content.style.height = 'auto'
      }, 800)
    }
  }
}
