import { gsap } from 'gsap'
export class MegaMenu {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementById('js-header') == null) return
      const header = document.getElementById('js-header')
      const headerNavi = header.querySelector('.l-header__navi')
      const trgAll = document.querySelectorAll('.js-mega_trg')
      const trgArr = Array.prototype.slice.call(trgAll, 0)
      const menuAll = document.querySelectorAll('.js-mega_menu')
      const menuArr = Array.prototype.slice.call(menuAll, 0)

      trgArr.forEach(function (target) {
        let parent = target.parentNode
        target.nextElementSibling.setAttribute('aria-hidden', true)
        parent.setAttribute('aria-expanded', false)

        parent.addEventListener('focusin', () => {
          visibleMenu(target)
        })
        parent.addEventListener('focusout', () => {
          hidddenMenu(target)
        })

        parent.addEventListener('mouseenter', () => {
          visibleMenu(target)
        })
        parent.addEventListener('mouseleave', () => {
          hidddenMenu(target)
        })
      })

      const visibleMenu = (elm) => {
        menuArr.forEach(function (menu) {
          gsap.to(menu, 0.4, { autoAlpha: 0 })
        })
        elm.classList.add('is-act')
        let currnetMenu = elm.nextElementSibling
        let itemHeight = elm.parentNode.clientHeight
        let itemPB = parseInt(window.getComputedStyle(headerNavi).paddingBottom, 10)
        let parent = elm.parentNode
        currnetMenu.style.top = itemHeight + itemPB + 20 + 'px'
        gsap.to(currnetMenu, 0.4, { autoAlpha: 1 })
        currnetMenu.setAttribute('aria-hidden', false)
        parent.setAttribute('aria-expanded', true)
      }
      const hidddenMenu = (elm) => {
        let currnetMenu = elm.nextElementSibling
        let parent = elm.parentNode
        gsap.to(currnetMenu, 0.4, { autoAlpha: 0 })
        elm.classList.remove('is-act')
        currnetMenu.setAttribute('aria-hidden', true)
        parent.setAttribute('aria-expanded', false)
      }
    })
  }
}
