import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export class WaySlider {
  constructor() {
    const sliderArr = Array.prototype.slice.call(document.querySelectorAll('.c-way_slider'), 0)
    sliderArr.forEach(function (elm) {
      let waySwiper = new Swiper(elm.querySelector('.js-way_slider'), {
        modules: [Navigation],
        loop: false,
        slidesPerView: 1,
        navigation: {
          nextEl: elm.querySelector('.js-way_arrow_next'),
          prevEl: elm.querySelector('.js-way_arrow_prev'),
        },
      })
    })
  }
}
