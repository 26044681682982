export class Opening {
  constructor() {
    if (document.querySelector('.p-index_kv') !== null) {
      const copy = document.getElementById('js-kv_copy')
      window.addEventListener('load', () => {
        setTimeout(function () {
          copy.classList.add('is-act')
        }, 1000)
      })
    }
  }
}
